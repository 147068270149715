<script setup>
import { ref, getCurrentInstance } from 'vue';

const app = getCurrentInstance().proxy;

const list = ref([]);
const option = ref([]);
const loading = ref(false);

const props = defineProps({
  size: {
    type: String,
    default: '',
  },
});
const emit = defineEmits('change');

function searchMethod(query) {
  loading.value = true;
  app.$axios.post(app.$api.goods_supplier_tag.page, {
    currentPage: 1,
    pageSize: 50,
    tagName: query,
  }).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      option.value = res.data.records;
    }
  });
}
function change() {
  emit('change', list.value);
}
function focus() {
  if (list.value.length === 0) {
    searchMethod();
  }
}
</script>

<template>
  <el-select
    v-model="list"
    style="width: 100%"
    multiple
    :multiple-limit="10"
    filterable
    remote
    placeholder="请输入标签名"
    :reserve-keyword="true"
    :size="props.size"
    :remote-method="searchMethod"
    :loading="loading"
    clearable
    @change="change"
    @focus="focus"
  >
    <el-option
      v-for="item in option"
      :key="item.id"
      :label="`${item.name}${item.belongType === 1 ?
        '（平台标签）' : `（商家标签）`}`"
      :value="item.id"
    />
  </el-select>
</template>

<style scoped lang="scss">

</style>
