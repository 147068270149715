var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        multiple: "",
        "multiple-limit": 10,
        filterable: "",
        remote: "",
        placeholder: "请输入标签名",
        "reserve-keyword": true,
        size: _setup.props.size,
        "remote-method": _setup.searchMethod,
        loading: _setup.loading,
        clearable: "",
      },
      on: { change: _setup.change, focus: _setup.focus },
      model: {
        value: _setup.list,
        callback: function ($$v) {
          _setup.list = $$v
        },
        expression: "list",
      },
    },
    _vm._l(_setup.option, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: {
          label: `${item.name}${
            item.belongType === 1 ? "（平台标签）" : `（商家标签）`
          }`,
          value: item.id,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }